import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'

import theme from '@ifca-root/react-component/src/assets/theme'
import { Tune } from '@material-ui/icons'
import EventIcon from '@material-ui/icons/Event'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import {
  useGetApAdvancebyStatusLazyQuery,
  useGetApCreditNotebyStatusLazyQuery,
  useGetApCreditorCreditNoteByStatusLazyQuery,
  useGetApCreditorDebitNotebyStatusLazyQuery,
  useGetApDebitNotebyStatusLazyQuery,
  useGetApInvoicebyStatusLazyQuery,
  useGetApPaymentbyStatusLazyQuery,
  useGetApRefundbyStatusLazyQuery,
  useGetCreditorAccountCompanyAssignmentNameQuery,
  useGetCreditorTotalDocAmtQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */
export const CreditorAccountTrxListing = (props: any) => {
  const { docType } = props
  let history = useHistory()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { CompanyID, CreditorAccountID }: any = useParams()
  const getSearch = localStorage.getItem('searchFilter')
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  const {
    loading: CreditorAccountCompanyAssignmentLoading,
    error: CreditorAccountCompanyAssignmentError,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: [],
    },
  } = useGetCreditorAccountCompanyAssignmentNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      CreditorAccountID: CreditorAccountID,
    },
  })

  const {
    loading: CreditorTotalDocAmtLoading,
    called: CreditorTotalDocAmtCalled,
    data: { getCreditorTotalDocAmt } = { getCreditorTotalDocAmt: [] },
  } = useGetCreditorTotalDocAmtQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const totalEachDocAmt = filteredList?.reduce(
    (previousValue, currentValue) => previousValue + currentValue?.DocAmt,
    0
  )

  let newFilteredListWithTotalDocAmt = getCreditorAccountCompanyAssignment.map(
    subject => {
      let otherSubject = getCreditorTotalDocAmt.find(
        element => element.CreditorAccountID === subject.CreditorAccountID
      )

      return {
        ...subject,
        ...otherSubject,
      }
    }
  )

  const [
    loadAPAdvance,
    {
      loading: getAPAdvancebyStatusLoading,
      data: { getAPAdvancebyStatus } = {
        getAPAdvancebyStatus: [],
      },
    },
  ] = useGetApAdvancebyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPAdvancebyStatus }) => {
      setOriginalListing(getAPAdvancebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadAPInvoice,
    {
      loading: APInvoicebyStatusLoading,
      data: { getAPInvoicebyStatus } = {
        getAPInvoicebyStatus: [],
      },
    },
  ] = useGetApInvoicebyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPInvoicebyStatus }) => {
      setOriginalListing(getAPInvoicebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadAPCreditorCreditNote,
    {
      loading: ApCreditorCreditNotebyStatusLoading,
      called: ApCreditorCreditNoteCalled,
      data: { getAPCreditorCreditNotebyStatus } = {
        getAPCreditorCreditNotebyStatus: [],
      },
    },
  ] = useGetApCreditorCreditNoteByStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditorCreditNotebyStatus }) => {
      setOriginalListing(getAPCreditorCreditNotebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadAPCreditNote,
    {
      loading: APCreditNotebyStatusLoading,
      data: { getAPCreditNotebyStatus } = { getAPCreditNotebyStatus: [] },
    },
  ] = useGetApCreditNotebyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditNotebyStatus }) => {
      setOriginalListing(getAPCreditNotebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadAPDebitNote,
    {
      loading: APDebitNotebyStatusLoading,
      data: { getAPDebitNotebyStatus } = {
        getAPDebitNotebyStatus: [],
      },
    },
  ] = useGetApDebitNotebyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPDebitNotebyStatus }) => {
      setOriginalListing(getAPDebitNotebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })
  const [
    loadAPCreditorDebitNote,
    {
      loading: APCreditorDebitNotebyStatusLoading,
      data: { getAPCreditorDebitNotebyStatus } = {
        getAPCreditorDebitNotebyStatus: [],
      },
    },
  ] = useGetApCreditorDebitNotebyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditorDebitNotebyStatus }) => {
      setOriginalListing(getAPCreditorDebitNotebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadAPPayment,
    {
      loading: getAPPaymentbyStatusLoading,
      data: { getAPPaymentbyStatus } = { getAPPaymentbyStatus: [] },
    },
  ] = useGetApPaymentbyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPPaymentbyStatus }) => {
      setOriginalListing(getAPPaymentbyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadAPRefund,
    {
      loading: getAPRefundbyStatusLoading,
      data: { getAPRefundbyStatus } = {
        getAPRefundbyStatus: [],
      },
    },
  ] = useGetApRefundbyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPRefundbyStatus }) => {
      setOriginalListing(getAPRefundbyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  useEffect(() => {
    if (docType === 'advance')
      loadAPAdvance({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
    else if (docType === 'invoice')
      loadAPInvoice({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
    else if (docType === 'credit-note')
      loadAPCreditNote({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
    else if (docType === 'creditor-credit-note')
      loadAPCreditorCreditNote({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
    else if (docType === 'debit-note')
      loadAPDebitNote({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
    else if (docType === 'creditor-debit-note')
      loadAPCreditorDebitNote({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
    else if (docType === 'payment')
      loadAPPayment({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
    else if (docType === 'refund')
      loadAPRefund({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          CreditorAccountID: CreditorAccountID,
        },
      })
  }, [docType])

  const PageTitle = docType => {
    switch (docType) {
      case 'advance':
        return 'Advances'
      case 'invoice':
        return 'Invoices'
      case 'payment':
        return 'Payment'
      case 'refund':
        return 'Refund'
      case 'credit-note':
        return 'Credit Note'
      case 'creditor-credit-note':
        return 'Credit Note from Creditor'
      case 'debit-note':
        return 'Debit Note'
      case 'creditor-debit-note':
        return 'Debit Note from Creditor'
      default:
        return ''
    }
  }

  return (
    <>
      {getAPAdvancebyStatusLoading && <Loading />}
      {APInvoicebyStatusLoading && <Loading />}
      {APCreditNotebyStatusLoading && <Loading />}
      {ApCreditorCreditNotebyStatusLoading && <Loading />}
      {APDebitNotebyStatusLoading && <Loading />}
      {getAPPaymentbyStatusLoading && <Loading />}
      {getAPRefundbyStatusLoading && <Loading />}
      {UserLoading && <Loading />}
      {CreditorAccountCompanyAssignmentLoading && <Loading />}
      {CreditorTotalDocAmtLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}`
          )
          localStorage.removeItem(`searchFilter`)
          localStorage.removeItem(`advancedFilter`)
        }} //<- back button action
        smTitle="Accounts Payable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Creditor Accounts' },
          { name: 'Creditor Accounts' },
          { name: PageTitle(docType), current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />
      {CreditorAccountID ? (
        <DynamicSubHeader
          title={
            <span>
              <span style={{ fontWeight: 'bold' }}>
                {
                  getCreditorAccountCompanyAssignment[0]?.CreditorAccount
                    ?.CompanyName
                }
              </span>
            </span>
          }
          infoLine={
            <span style={{ fontWeight: 'bold' }}>{PageTitle(docType)}</span>
          }
          rightInfoLine={
            <>
              <span>
                <>
                  <span className="c-orange xsTitle">
                    {amtStr(totalEachDocAmt)}
                  </span>
                </>
              </span>
            </>
          }
        />
      ) : null}

      <div>
        <SearchHeader
          title={`${PageTitle(docType)} Listing`}
          value={`${advancedFilterList(filteredList)?.length}`}
          search
          fixed
          multiDynamicInfo
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'DocNo',
              'RefNo',
              'DocDate',
              'Description',
            ])
          }}
          onCloseAction={() => handleSearch('', [])}
          option={{
            icon: <Tune />,
            onClick: () =>
              history.push(`/account-payable/${CompanyID}/advance/filter`),
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch ? getSearch : ''}
        />
      </div>
      <ContentWrapper style={{ marginTop: isDesktop ? '153px' : '90px' }} float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.sort((a, b) => {
                return a.DocDate.localeCompare(b.DocDate)
              })
              ?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="flex-space mdLabel"
                            style={{ fontSize: '12px' }}
                          >
                            {el?.DocNo}
                          </span>
                          <span
                            className="desc"
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            <span className="mdLabel">
                              <IconText
                                icon={<EventIcon />}
                                font="mdLabel"
                                children={formatDate(el?.DocDate)}
                              ></IconText>
                            </span>
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={6}>
                              <span style={{ fontSize: '10px' }}>
                                {el?.CreditorAccount?.CompanyName}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: 'end',
                              }}
                            >
                              {docType === 'refund' &&
                              el?.Allocation[0]?.DebitRefTable ===
                                'AP_Advance' ? (
                                <span className="xxTitle ">
                                  <span
                                    style={{
                                      fontSize: '10px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {amtStr(el?.DocAmt)}
                                  </span>
                                </span>
                              ) : (
                                <span className="xxTitle c-orange">
                                  <span
                                    style={{
                                      fontSize: '10px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {amtStr(el?.DocAmt)}
                                  </span>
                                </span>
                              )}
                            </Grid>
                          </Grid>

                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={6}>
                              <span style={{ fontSize: '10px' }}>
                                {el?.Allocation?.map(
                                  alloc =>
                                    alloc?.[`${alloc?.CreditOrDebit}DocNo`]
                                )?.join(' | ') ?? '-'}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: 'end',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '10px',
                                  color: 'grey',
                                  fontWeight: 'bold',
                                }}
                              >
                                {amtStr(el?.BalanceAmt)}
                              </span>
                            </Grid>
                          </Grid>
                          <div>
                            <div
                              className="desc text-overflow"
                              style={{ width: '340px' }}
                            >
                              {el?.Description}
                            </div>
                          </div>
                        </>
                      }
                    />
                  </ListItem>
                )
              })
          )}
        </List>
      </ContentWrapper>
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
